import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import d4ulogo from "../Images/d4ulogo.png";
import {auth} from '../FirebaseInit.js';
import { onAuthStateChanged } from "firebase/auth";
import { signOut } from "firebase/auth";
import { useSelector,useDispatch } from "react-redux";
import { populate_bg } from "./Redux/AppSlice";
import {Link} from 'react-router-dom'
const Container = tw.div`bg-white items-center w-full justify-between flex py-4 md:px-20 px-6 border-b border-black`;
const NavButton = tw.div`md:mx-6 my-4`;
const HeaderLogoContainer = tw.div`w-16 ml-4`;

const Actions = tw.div`flex flex-col sm:flex-row justify-end w-full text-right my-auto  pr-20`;

const Link1 = tw.a`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex 
  justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-primaryOrange hocus:bg-primaryOrange text-primaryBlue 
  shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default () => {

  const onClickedSignout=()=>
  {
    signOut(auth).then(() => {
      alert('Successfully Signed Out');
      }).catch((error) => {
        // An error happened.
      });
  };
  const[user,set_user]=useState();
  useEffect(()=>
  {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        set_user(user);
      } else {
        set_user(null)
      }
    }); 
  })

  const dispatch=useDispatch();
  const signed_in_state=useSelector((state)=>state.app.value);
  const onSignoutClicked=()=>
  {
    signOut(auth).then(() => {
    alert('Successfully Signed Out');
    }).catch((error) => {
      // An error happened.
    });
  }
  const open_signin_form=()=>
  {
    dispatch(populate_bg(true));
  }
  return (
    <Container>
      <a href="/">
        <HeaderLogoContainer>
          <img src={d4ulogo} />
        </HeaderLogoContainer>
      </a>
      
      


            {user===null?


<Actions>
<Link1 onClick={()=>open_signin_form()}>Login</Link1>
</Actions>


       :
       <Actions>
             <Link1 onClick={()=>onClickedSignout()}>Logout</Link1>
             </Actions>
} 

    </Container>
  );
};
