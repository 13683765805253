import {createSlice} from '@reduxjs/toolkit';
const initialState = {
  value: {
   is_sign_in:true
  },
};
export const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: 
  {
    populate_bg: (state, action) => 
    {
      state.value=action.payload;
    },
  },
});
export const {populate_bg} = AppSlice.actions;

export default AppSlice.reducer;
