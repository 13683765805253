import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import tw from "twin.macro";
import styled from "styled-components";
import planextlogo from "../Images/d4ulogo.png";
import {signInWithEmailAndPassword } from "firebase/auth";
import { populate_bg } from "./Redux/AppSlice";
import NavBar from "./NavBar";
import {auth} from '../FirebaseInit.js';
const Container = tw.div`flex items-center bg-primaryBackground mx-80 mt-32`;
const LeftContainer = tw.div`w-1/2 p-40 flex justify-center border-r-4 border-SecondaryBackground`;
const RightContainer = tw.div`w-1/2 flex justify-center `;
const Heading = tw.div`text-3xl text-center font-semibold text-textprimary`;
const FormContainer = styled.div`
  ${tw`  text-gray-100 rounded-lg relative  `}
  form {
    ${tw`  pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-black text-base font-medium tracking-wide border-b-2 py-2 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div``;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute text-white top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;

const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;

const Link1 = tw.a`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex 
  justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-textprimary hocus:bg-primaryOrange text-primaryBlue 
  shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;
export default () => {
  //const navigate=useNavigate();
  const dispatch=useDispatch();
  //const sign_in_state=useSelector((state)=>state.app.value);
  const[email,set_email]=useState("");
  const[password,set_password]=useState("");
  const onClickSignIn=()=>
  {
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
   //   dispatch(populate_bg(true))
     // alert('Successfully Signed In');
    //  navigate('/');
    })
    .catch((error) => {
    alert(error.message);
    });
  }
  const onClickCancel=()=>
  {
    dispatch(populate_bg(false));
  }
  const onChangeEmail=(e)=>
  {
    e.preventDefault();
    set_email(e.target.value);
  }
  const onChangePassword=(e)=>
  {
    e.preventDefault();
    set_password(e.target.value);
  }
  return (
   
    <Container>
      <LeftContainer>
        <img src={planextlogo} width="60%" />
      </LeftContainer>
      <RightContainer>
        <FormContainer>
          <Heading>Fill Details to Login</Heading>
          <form action="#">
            <InputContainer>
              <Label htmlFor="name-input">Email Address</Label>
              <Input
                id="email"
                type="text"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e)=>onChangeEmail(e)}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="name-input">Password</Label>
              <Input
                id="password"
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e)=>onChangePassword(e)}
              />
            </InputContainer>

            <Actions>
            <Link1 
              // href="/Login"
              onClick={()=>onClickCancel()}
              >Back</Link1>
              <Link1 
              // href="/Login"
              onClick={()=>onClickSignIn()}
              >Login</Link1>
            </Actions>

          </form>
        </FormContainer>
      </RightContainer>
    </Container>
  
  );
};
