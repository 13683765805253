import React, { useState,useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import bannerimage from "../../Images/bannerimage.png";
import {storage} from '../../FirebaseInit.js';
import {ref, uploadBytesResumable, getDownloadURL,deleteObject} from "firebase/storage";
import { doc, setDoc,getDoc,updateDoc,arrayUnion,arrayRemove,collection,addDoc,getDocs, increment, FieldValue, serverTimestamp } from "firebase/firestore"; 
import {db} from '../../FirebaseInit.js';
import Imagepreview from "../ImagePreview";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { black } from "tailwindcss/colors";
import { async } from "@firebase/util";
import { messaging } from "../../FirebaseInit.js";
import {app} from '../../FirebaseInit.js'
import {getMessaging} from 'firebase/messaging';
import axios from "axios";
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` rounded bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.a`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.a`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const BlueButton = tw.a`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center cursor-pointer bg-primaryBlue hocus:bg-SecondaryBackground  text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const FormContainer = styled.div`
  ${tw`  bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-primaryBlue text-base font-medium tracking-wide border-b-2 py-2 text-primaryBlue hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-base`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;

const SelectContainer = tw.select`text-black bg-gray-200 py-2 rounded`
const OptionsContainer = tw.option`my-2`

const Link1 = tw.a`cursor-pointer p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-primaryBlue hocus:bg-SecondaryBackground text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;
export default ({ cards = null }) => {
  const[users_array,set_users_array]=useState([]);
  useEffect(()=>
  {
    GetAllUsers();
  },[])
  async function GetAllUsers()
  {
    let temp_users=[]; 
    const querySnapshot = await getDocs(collection(db, "Users"));
querySnapshot.forEach((doc) => {

    temp_users.push(doc.data().user_token);
  
});
set_users_array(temp_users);
console.log(users_array);
  }
  const [startDate, setStartDate] = useState();
  const [show_upload_modal, set_show_upload_modal] = useState(true);
  const[notification_title,set_notification_title]=useState("");
  const[notification_description,set_notification_description]=useState("");
  const onFixDate=(date)=>
  {
    console.log(date.getMonth());
   // set_banner_date(date.getDate()+"-"+(Number(date.getMonth())+1)+"-"+date.getFullYear());
   // set_open_date_picker(false);
  };
  const onChangeNotificationTitle=(e)=>
  {
    set_notification_title(e.target.value);
  }
  const onChangeNotificationDescription=(e)=>
  {
    set_notification_description(e.target.value);
  }
  const onCreateAndSendNotification=()=>
  {
    if(notification_description.length>0 && notification_title.length>0)
    {
    for(let i=0;i<users_array.length;i++)
    {
    var data = JSON.stringify({
      "notification": {
        "title": notification_title,
        "body": notification_description,
        "subtitle":"test",
      },
      "to": users_array[i]
    });
    
    var config = {
      method: 'post',
      url: 'https://fcm.googleapis.com/fcm/send',
      headers: { 
        'Authorization': 'key=AAAAHwtlAmY:APA91bEecYgZme3UtVkAwDb1_CvrvouRg9nf-LAg0ESkvmojf8PJYHFSMUr-Hup4hiy6du6IwqRvJcz1-rG63smEnqcaqXSBRXgGo_wRW7y9TxBEs8ag8Tv7oDggdxf1iT0aR2rtN7tE ', 
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios(config)
.then(function (response) {

  console.log(JSON.stringify(response.data));
  AddTheNotification();
})
.catch(function (error) {
  console.log(error);
});
  }
}
else 
  {
    alert('Fields are Empty');
  }
}
async function AddTheNotification()
{
await addDoc(collection(db,"Notifications"),
{
  notification_title: notification_title,
  notification_description: notification_description,
  date_of_creation:new Date().getHours()+":"+new Date().getMinutes()+new Date().getSeconds()
} );
  set_notification_title("");
  set_notification_description("");
}
  // const[file,set_file]=useState();
  // const[image_to_show,set_image_to_show]=useState();
  // const onChangeFile=(e)=>
  // {
  //     set_file(e.target.files[0]);
  //     set_image_to_show(URL.createObjectURL(e.target.files[0]));
  // }
  // const[download_url,set_download_url]=useState("");
  // const UploadAnImage=()=>
  // {
  //     if(banner_name.length>0 && banner_purpose.length>0 && banner_date.length>0)
  //     {
  //     const storageRef = ref(storage,banner_purpose+"/"+banner_name+".jpg");

  //     const uploadTask = uploadBytesResumable(storageRef, file);
      
  //     // Register three observers:
  //     // 1. 'state_changed' observer, called any time the state changes
  //     // 2. Error observer, called on failure
  //     // 3. Completion observer, called on successful completion
  //     uploadTask.on('state_changed', 
  //       (snapshot) => {
  //         // Observe state change events such as progress, pause, and resume
  //         // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  //         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //         console.log('Upload is ' + progress + '% done');
  //         switch (snapshot.state) {
  //           case 'paused':
  //             console.log('Upload is paused');
  //             break;
  //           case 'running':
  //             console.log('Upload is running');
  //             break;
  //         }
  //       }, 
  //       (error) => {
  //         // Handle unsuccessful uploads
  //       }, 
  //       () => {
  //         // Handle successful uploads on complete
  //         // For instance, get the download URL: https://firebasestorage.googleapis.com/...
  //         getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //           console.log('File available at', downloadURL);
  //           set_download_url(downloadURL);
  //           if(downloadURL.length!==0)
  //           {
  //           AddTheData(downloadURL)
  //           }
  //           else
  //           {
  //             alert("Banner Not Uploaded Successfully");
  //           }
  //         });
  //       }
  //     );
  //     }
  //     else
  //     {
  //         alert("Fields Are Empty");
  //     }
  // }
  // async function AddTheData(_download_url)
  // {
  //   const docRef = doc(db, "Banners",banner_purpose);
  //   const docSnap = await getDoc(docRef);
  //   if (docSnap.exists()) 
  //   {
  //     await updateDoc(doc(db,"Banners",banner_purpose), {
  //       banner_array: arrayUnion({banner_name: banner_name,banner_date:banner_date,banner_url:_download_url,banner_purpose:banner_purpose})
  //   });
  //   alert("Banner Uploaded Successfully");
  //   let temp_banner=[];
  //   temp_banner.push({banner_name: banner_name,banner_date:banner_date,banner_url:_download_url,banner_purpose:banner_purpose});
  //   for(let i=0;i<banners_array.length;i++)
  //   {
  //     temp_banner.push(banners_array[i]);
  //   }
  //   set_banners_array(temp_banner);
  //       if(banner_purpose==="Festivals")
  //       {
  //       await updateDoc(doc(db, "Metadata","Metadata"), {
  //         festival_banner_count:increment(1)
  //       });
  //       }
  //       else if(banner_purpose==="Advertisements")
  //       {
  //         await updateDoc(doc(db, "Metadata","Metadata"), {
  //           advertisement_banner_count:increment(1)
  //         });
  //       }
  //       set_banner_name("");
  //       set_banner_date("");
  //       set_banner_purpose("Festivals");
  //       set_image_to_show("");
  //       set_file("");
  //   } 
  //   else 
  //   {
  //     // doc.data() will be undefined in this case
  //     console.log("No such document!");
  //     await setDoc(doc(db, "Banners",banner_purpose), 
  //     {
  //         banner_array:[{banner_name: banner_name,banner_date:banner_date,banner_url:_download_url,banner_purpose:banner_purpose}]
  //     });
  //     alert("Banner Uploaded Successfully");
  //     let temp_banner=[];
  //     temp_banner.push({banner_name: banner_name,banner_date:banner_date,banner_url:_download_url,banner_purpose:banner_purpose});
  //     for(let i=0;i<banners_array.length;i++)
  //     {
  //       temp_banner.push(banners_array[i]);
  //     }
  //     set_banners_array(temp_banner);
  //       if(banner_purpose==="Festivals")
  //       {
  //       await updateDoc(doc(db, "Metadata","Metadata"), {
  //         festival_banner_count:increment(1)
  //       });
  //       }
  //       else if(banner_purpose==="Advertisements")
  //       {
  //         await updateDoc(doc(db, "Metadata","Metadata"), {
  //           advertisement_banner_count:increment(1)
  //         });
  //       }
  //       set_banner_name("");
  //       set_banner_date("");
  //       set_banner_purpose("Festivals");
  //       set_image_to_show("");
  //       set_file("");
  //   }
     
  // }
  // async function delete_the_banner(item_purpose,item_name,item_banner_url,item_date)
  // {
  //   deleteObject(ref(storage,item_purpose+"/"+item_name+".jpg")).then(() => {
  //   RemoveTheInformation(item_purpose,item_name,item_date,item_banner_url)
  //     // File deleted successfully
  //   }).catch((error) => {
  //     alert(error);
  //   });
  // }
  // async function RemoveTheInformation(_purpose,_name,_date,_banner_url)
  // {
  //   await updateDoc(doc(db, "Banners",_purpose), {
  //     banner_array: arrayRemove({banner_name:_name,banner_date:_date,banner_url:_banner_url,banner_purpose:_purpose})
      
  // });
  // if(_purpose==="Festivals")
  // {
  // await updateDoc(doc(db, "Metadata","Metadata"), {
  //   festival_banner_count:increment(-1)

  // });
  // alert('Successfully Removed The Banner');
  // banners_array.splice(banners_array.findIndex(e=>e.banner_url===_banner_url),1);
  // let temp_banner=[];
  // for(let i=0;i<banners_array.length;i++)
  // {
  //   temp_banner.push(banners_array[i]);
  // }
  // set_banners_array(temp_banner);
  // }
  // else if(_purpose==="Advertisements")
  // {
  //   await updateDoc(doc(db, "Metadata","Metadata"), {
  //     advertisement_banner_count:increment(-1)
  //   });
  //   alert('Successfully Removed The Banner');
  //   banners_array.splice(banners_array.findIndex(e=>e.banner_url===_banner_url),1);
  //   let temp_banner=[];
  //   for(let i=0;i<banners_array.length;i++)
  //   {
  //     temp_banner.push(banners_array[i]);
  //   }
  //   set_banners_array(temp_banner);
  // }
  // }
  return (
    <Container>
      {show_upload_modal === true ? (
        <FormContainer>
          <form action="#">
            <TwoColumn>
              <ColumnForm>
                <InputContainer>
                  <Label htmlFor="name-input">Notification Title</Label>
                  <Input
                    id="notification-title"
                    type="text"
                    name="notificationtitle"
                    placeholder="Notification Title"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "Notifiaction Title")}
                    value={notification_title}
                    onChange={(e)=>onChangeNotificationTitle(e)}
                  />
                </InputContainer>
                <InputContainer>
                  <Label htmlFor="name-input">Notification Description</Label>
                  <Input
                    id="notification-description"
                    type="text"
                    name="notificationdescription"
                    placeholder="Notification Description"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "Notifiaction Description")}
                    value={notification_description}
                    onChange={(e)=>onChangeNotificationDescription(e)}
                  />
                </InputContainer>
                
                {/* <InputContainer onClick={()=>set_open_date_picker(true)}>
                  <Label htmlFor="name-input">Date</Label>
                  <Input
                    id="date"
                    type="text"
                    name="date"
                    placeholder="Input Date"
                    contentEditable={false}
                    value={banner_date}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "Input Date")}
                  />
                </InputContainer>
                {
                open_date_picker===true?
                <DatePicker color={black} border={false} open={open_date_picker}  onChange={(val) => onFixDate(val)} />
                :
                null
                }*/}
              </ColumnForm>
              {/* <ColumnForm>
              <InputContainer> 
        <Label htmlFor="name-input">Browser Banner Image to Upload</Label>
        <Input
          id="status"
          type="file"
          onChange={(e)=>onChangeFile(e)}
        />
        <img
          style={{
            height: "150px",
            color: "black",
            fontSize: 14,
            marginLeft: "18px",
          }}
          src={image_to_show}
          alt="Banner Preview"
        />
      </InputContainer>
                
              </ColumnForm> */}
            </TwoColumn>

            <Actions>
              <Link1 
              onClick={()=>onCreateAndSendNotification()}
              //onClick={()=>UploadAnImage()}
              >Create Notification</Link1>
            </Actions>
          </form>
        </FormContainer>
      ) : null}

      {/* <TableContainer>
        <TableTop>
          <TableTitle style={{ width: "13%" }}>Banner Image</TableTitle>

          <TableTitle style={{ width: "28%" }}>Banner Name</TableTitle>

          <TableTitle style={{ width: "28%" }}>Banner Date</TableTitle>
          <TableTitle style={{ width: "28%" }}>Banner Purpose</TableTitle>
          <TableTitle style={{ width: "18%" }}>Actions</TableTitle>
        </TableTop> */}

        {/* {
        banners_array.map((card, i) => (
          <Column key={i}>
            <Card>
              <TableDetails style={{ width: "13%" }}>
                <img src={card.banner_url} />
              </TableDetails>

              <TableDetails style={{ width: "28%" }}>
                {card.banner_name}
              </TableDetails>

              <TableDetails style={{ width: "28%" }}>
                {card.banner_date}
              </TableDetails>
              <TableDetails style={{ width: "28%" }}>
                {card.banner_purpose}
              </TableDetails>
              <TableDetails style={{ width: "18%" }}>
                <ActionIconsColumn>
                

                  <IconContainer onClick={()=>delete_the_banner(card.banner_purpose,card.banner_name,card.banner_url,card.banner_date)}>
                    <AiOutlineDelete />
                  </IconContainer>
                </ActionIconsColumn>
              </TableDetails>
            </Card>
          </Column>
        ))} */}
          {/* <IconContainer>
                    <AiOutlineEdit />
                  </IconContainer> */}
        {/* <ButtonsContainer>
          <Button href="/Login">
            <AiOutlineArrowLeft />
            <ButtonText>Previous</ButtonText>
          </Button>

          <BlueButton onClick={() => set_show_upload_modal(true)}>
            Create New Notification
          </BlueButton>
          <Button href="/Login">
            <ButtonText>Next</ButtonText>
            <AiOutlineArrowRight />
          </Button>
        </ButtonsContainer>
      </TableContainer> */}
    </Container>
  );
};
