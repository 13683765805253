import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { PrimaryButton, Link2 } from "./MiscStyles/Buttons";

import { useSelector } from "react-redux";

import Dashboard from "./ContentPage/Dashboard";
import NotificationsTable from "./ContentPage/NotificationsTable";
import BannerTable from "./ContentPage/BannerTable";
import PrivacyPolicy from "./PrivacyPolicy.js";
import YoutubeLinkUpload from "./ContentPage/YoutubeLinkUpload";
const Container = tw.div`w-10/12 flex justify-center`;
const Content = tw.div`fixed right-0 w-10/12 `;
const TestDisplayArea = tw.div`py-40 bg-gray-600`;
const Text = tw.p`text-blue-700 font-bold text-2xl text-center`;

export default () => {
  const [home, setHome] = useState(false);
  const [category, setCategory] = useState(false);
  const [service, setService] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [provide, setProvide] = useState(false);
  const [advertisement, setAdvertisement] = useState(false);

  const number = useSelector((state) => state.callComponent.value);
  const EnableDisable = (val) => {
    setHome(false);
    setCategory(false);
    setService(false);
    setCustomer(false);
    setProvide(false);
    setAdvertisement(false);
    switch (val) {
      case 1:
        setHome(true);
        break;
      case 2:
        setCategory(true);
        break;
      case 3:
        setService(true);
        break;
      case 4:
        setCustomer(true);
        break;
      case 5:
        setProvide(true);
        break;
      case 6:
        setAdvertisement(true);
        break;
    }
  };
  useEffect(() => {
    console.log(number);
    EnableDisable(number);
  }, []);

  return (
    <Container className="Content">
      {number === 1 ? <Dashboard /> : null}
      {number === 2 ? <BannerTable /> : null}
      {number === 3 ? <NotificationsTable/> : null}
      {number ===  4? <PrivacyPolicy/> : null}
      {number ===  6? <YoutubeLinkUpload/> : null}
    </Container>
  );
};
