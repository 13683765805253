import React, { Component, useState } from "react";
import tw from "twin.macro";

const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-base`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;

class Imagepreview extends React.Component {
  state = {
    previewimg: "",
  };

  imgselectHandler(e) {
    if (e.target.files.length !== 0) {
      this.setState({
        previewimg: URL.createObjectURL(e.target.files[0]),
      });
    }
  }

  render() {
    return (
      <InputContainer>
        <Label htmlFor="name-input">Browser Banner Image to Upload</Label>
        <Input
          id="status"
          type="file"
          onChange={this.imgselectHandler.bind(this)}
        />
        <img
          style={{
            height: "150px",
            color: "black",
            fontSize: 14,
            marginLeft: "18px",
          }}
          src={this.state.previewimg}
          alt="Banner Preview"
        />
      </InputContainer>
    );
  }
}
export default Imagepreview;
