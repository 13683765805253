import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: 1,
};

export const ComponentSlice = createSlice({
  name: "callComponent",
  initialState,
  reducers: {
    componentReducer: (state, action) => {
      switch (action.payload) {
        case 1:
          state.value = 1;
          break;

        case 2:
          state.value = 2;
          break;
        case 3:
          state.value = 3;
          break;
        case 4:
          state.value = 4;
          break;
        case 5:
          state.value = 5;
          break;
        case 6:
          state.value = 6;
          break;
        case 7:
          state.value = 7;
          break;
        case 8:
          state.value = 8;
          break;
      }
    },
  },
});

export const { componentReducer } = ComponentSlice.actions;

export default ComponentSlice.reducer;
