import React from "react";
import tw from "twin.macro";
import Accounting from "../../Images/Accounting.png";
import Marketing from "../../Images/Marketing.png";
import Design from "../../Images/Design.png";
import AutomotiveJobs from "../../Images/AutomotiveJobs.png";
import { BsBriefcase } from "react-icons/bs";
import { IoNewspaperOutline } from "react-icons/io5";
import { BsChatDots } from "react-icons/bs";
import { BsBookmark } from "react-icons/bs";
import { RiAdvertisementLine } from "react-icons/ri";
import { BiCategory } from "react-icons/bi";
import { MdHomeRepairService } from "react-icons/md";
import { BsCartCheck } from "react-icons/bs";
import {auth} from '../../FirebaseInit.js';
import { signOut } from "firebase/auth";
import { useSelector,useDispatch } from "react-redux";
const Container = tw.div`relative w-full pl-20`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-base`;
const CountDescriptionContainer = tw.div`flex-col `;

const CountContainer = tw.div` text-3xl  font-bold`;

const DescriptionContainer = tw.div` text-xl font-semibold`;

const LeftContainer = tw.div`text-3xl   rounded-xl p-4`;
const RightContainer = tw.div` flex-col pl-6`;

const CardContianer = tw.div`w-full flex flex-col flex-wrap items-center md:items-stretch md:flex-row  md:justify-start`;

const Card = tw.div`flex w-twentypercent flex-col sm:flex-row items-center m-6 bg-white text-primaryOrange sm:items-start text-center sm:text-left px-6 py-6  rounded-xl`;

const Actions = tw.div`flex flex-col sm:flex-row justify-end w-full text-right mt-8 ml-0 mb-2 pr-20`;

const Link1 = tw.a`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex 
  justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-primaryOrange hocus:bg-primaryOrange text-primaryBlue 
  shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

 const Heading = tw.h2`text-4xl font-semibold text-primaryOrange pt-20 pb-10`;


export default ({
  cards = null,
  heading = "APPLICATION STATISTICS",
  subheading = "2020 jobs live - 293 added today. ",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
}) => {
  const defaultCards = [
    {
      imageSrc: Accounting,
      title: "29",
      description: "Customers",
    },
    {
      imageSrc: Marketing,
      title: "3",
      description: "Categories",
    },

    {
      imageSrc: Design,
      title: "8",
      description: "Services",
    },

    {
      imageSrc: AutomotiveJobs,
      title: "2",
      description: "Providers",
    },
    {
      imageSrc: Accounting,
      title: "0",
      description: "Advertisements",
    },
    {
      imageSrc: Marketing,
      title: "28",
      description: "Posts",
    },

    {
      imageSrc: Design,
      title: "0",
      description: "Comments",
    },

    {
      imageSrc: AutomotiveJobs,
      title: "0",
      description: "Orders",
    },
  ];
  const onClickedSignout=()=>
  {
    signOut(auth).then(() => {
      alert('Successfully Signed Out');
      }).catch((error) => {
        // An error happened.
      });
  };
  //const meta_data=useSelector((state)=>state.meta.value);
  // console.log(meta_data);
  return (
    <Container>
      {/* <Actions>
            <Link1 onClick={()=>onClickedSignout()}>Logout</Link1>
            </Actions> */}
            <Heading>Welcome To DAY4U ADMIN</Heading>
      <CardContianer> 
         <Card>
          <LeftContainer style={{ backgroundColor: "#334155" }}>
            <BsBriefcase />
          </LeftContainer> 

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Users</DescriptionContainer>
              <CountContainer>0</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>

        <Card >
          <LeftContainer style={{ backgroundColor: "#334155" }}>
            <BiCategory />
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Festival Banners</DescriptionContainer>
              <CountContainer>0</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>
         <Card >
          <LeftContainer style={{ backgroundColor: "#334155" }}>
            <BsCartCheck />
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Official Banners</DescriptionContainer>
              <CountContainer>0</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
      </Card>
      </CardContianer>  
    </Container>
  );
};
