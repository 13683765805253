import {createSlice} from '@reduxjs/toolkit';
const initialState = {
  value: {
    user_count:0,
    festival_banner_count:0,
    advertisement_banner_count:0
  },
};
export const MetaSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: 
  {
    populate_meta: (state, action) => 
    {
      switch(action.payload.type)
      {
        case "User":
          state.value.user_count=action.payload.sol;
          break;
        case "FestivalBanner":
          state.value.festival_banner_count=action.payload.sol;
          break;
        case "AdvertisementBanner":
          state.value.advertisement_banner_count=action.payload.sol;
          break;
      }
    },
  },
});
export const {populate_meta} = MetaSlice.actions;

export default MetaSlice.reducer;
