import React from "react";
import tw from "twin.macro";

import logo from "../../Images/d4ulogo.png";
import columnImage1 from "../../Images/columnImage1.png";
import columnImage2 from "../../Images/columnImage2.png";
import columnImage3 from "../../Images/columnImage3.png";
import slider from "../../Images/slider.png";

const Container = tw.div``;
const TwoColumns = tw.div`flex justify-center`;
const Column = tw.div`w-1/2 flex justify-center items-center my-10`;
const TextContainer = tw.div``;

const ThreeColumns = tw.div`flex bg-white items-center justify-center`;
const LeftColumn = tw.div`w-1/2 flex justify-end`;
const CenterColumn = tw.div``;
const RightColumn = tw.div`w-1/2 flex justify-start`;

const ContentContainer = tw.div``;
const AppContainer = tw.div`relative`;
const BodyContainer = tw.div`flex relative`;

const Subheading = tw.div`text-sub font-semibold text-lg mb-6`;
const Heading = tw.div`text-white font-bold md:text-5xl text-2xl mb-6`;
const Paragraph = tw.div`text-white text-xl font-light mb-6`;

const List = tw.li`my-4`;
const FooterHeading = tw.div`text-white bg-sub text-base py-2 text-center`;

export default () => {
  return (
    <Container>
      <TwoColumns
        style={{
          backgroundColor: "#04103D",
          paddingBottom: 100,
          paddingTop: 100,
        }}
      >
        <Column>
          <img src={logo} width="20%" />
        </Column>
        <Column>
          <TextContainer style={{ marginRight: "250px" }}>
            <Subheading>ABOUT US</Subheading>
            <Heading>
              Our apps and workflows are born from challenging the status
            </Heading>
          </TextContainer>
        </Column>
      </TwoColumns>
      <TwoColumns style={{ backgroundColor: "white", color: "black" }}>
        <Column>
          <TextContainer style={{ marginLeft: "250px" }}>
            <Heading style={{ color: "black" }}>
              Our apps and workflows are born from challenging the status
            </Heading>
            <Paragraph style={{ color: "black" }}>
              Planext4U is a new trendy platform which facilitates Services and
              Providers including Social media networking platform, including
              buying and selling platform first time in India, where people
              share work with each other & create great opportunities, their
              beautiful life stories & skills in their interests here in social
              media networking platform including buying and selling programmes.
            </Paragraph>
          </TextContainer>
        </Column>
        <Column>
          <img src={columnImage1} width="30%" />
        </Column>
      </TwoColumns>
      <TwoColumns style={{ backgroundColor: "white" }}>
        <Column>
          <img src={columnImage2} width="30%" />
        </Column>
        <Column>
          <TextContainer style={{ marginRight: "250px" }}>
            <Heading style={{ color: "black" }}>Clear Your Mind</Heading>
            <Paragraph style={{ color: "black" }}>
              Reach that mental clarity you’ve been longing for. Your to-do
              lists are automatically sorted into Today, Upcoming and custom
              Filter views to help you focus on your most important things.
            </Paragraph>
          </TextContainer>
        </Column>
      </TwoColumns>
      <TwoColumns style={{ backgroundColor: "white" }}>
        <Column>
          <TextContainer style={{ marginLeft: "250px" }}>
            <Heading style={{ color: "black" }}>
              “Day4u makes it easy to go as simple or as complex as you want”
            </Heading>
            <Paragraph style={{ color: "black" }}>
              With you everywhere Use DAY4U apps, extensions and widgets on any
              device or platform.
            </Paragraph>
          </TextContainer>
        </Column>
        <Column>
          <img src={columnImage3} width="30%" />
        </Column>
      </TwoColumns>

      <ThreeColumns
        style={{
          backgroundColor: "#FFFAF5",
          paddingTop: 100,
          paddingBottom: 100,
        }}
      >
        <LeftColumn>
          <img src={logo} width="20%" />
        </LeftColumn>
        <CenterColumn style={{ paddingLeft: 80, paddingRight: 80 }}>
          <img src={slider} width="100%" />
        </CenterColumn>
        <RightColumn>
          <TextContainer>
            <ul>
              <List>Add a task</List>
              <List>Give it a due date</List>
              <List>Schedule a reminder</List>
              <List>Give tasks a priority level</List>
              <List>Complete the task</List>
            </ul>
          </TextContainer>
        </RightColumn>
      </ThreeColumns>
    </Container>
  );
};
