import tw from "twin.macro";
import React,{useState,useEffect} from "react";
import { store } from "../Components/Redux/Store";
import { Provider } from "react-redux";
import NavBar from "../Components/NavBar";
import Sidebar from "../Components/Sidebar";
import Content from "../Components/Content";
import SignIn from "../Components/SignIn";
import { useSelector,useDispatch } from "react-redux";
import {auth} from '../FirebaseInit.js';
import {Link} from 'react-router-dom'
import { onAuthStateChanged } from "firebase/auth";
import TwoColumnSection from "../Components/ContentPage/TwoColumnSection";
import bannerHomePage from "../Images/banner2.jpg";
import homeart from "../Images/homeart.jpg";
import d4ulogo from "../Images/d4ulogo.png";


const AppContainer = tw.div`relative`;
const BodyContainer = tw.div`flex relative`;
const Container = tw.div``;
const ContentContainer = tw.div`flex bg-white`;
const LeftContainer = tw.div``;
const RightContainer = tw.div``;
const HalfContainer = tw.div`w-1/2 flex justify-center items-center `
const ImageContainer = tw.div`flex justify-center pl-8 pb-4`
const FooterHeading = tw.div`flex justify-between px-80 bg-white text-black text-sm font-semibold py-3 text-center`;

const DownloadButton = tw.div`  `;

const Action = tw.div`flex justify-center  w-full text-right my-auto `;

const Link1 = tw.a`w-40 p-2 sm:p-3 text-base sm:text-base cursor-pointer font-semibold tracking-wider rounded-lg inline-flex 
  justify-center items-center  first:ml-0 bg-primaryOrange  text-primaryBlue `;

const ProviderPage=()=>
{
  const[user,set_user]=useState();
  useEffect(()=>
  {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        set_user(user);
      } else {
        set_user(null)
      }
    }); 
  })
  //const user=useSelector((state)=>state.app.value);
  const signin_state=useSelector((state)=>state.app.value);
  return (
    <>
    {user===null?
      <>
      {signin_state===true?
       
        <SignIn/>
        :
      <>
    <NavBar/>
  <Container>
    <ContentContainer>
      <HalfContainer>
       <DownloadButton>
        <ImageContainer>
        <img src={d4ulogo} width="40%"/></ImageContainer>
        <Action>
             <Link1  href="https://play.google.com/store/apps/details?id=com.pforu.dayforu">Download Now !</Link1></Action>
             </DownloadButton>
             </HalfContainer>
      <HalfContainer>
      <img src={homeart} width="100%" /></HalfContainer>
    </ContentContainer>
    <TwoColumnSection />
    <FooterHeading>
      <LeftContainer>
         D4u Private Limited © Copyrights 2022. All Rights Reserved. | Designed & Developed by <a href="https://illusionspotline.com/">ISL</a>
      </LeftContainer>
      <RightContainer>
        <Link to="/PrivacyPolicy">Privacy Policy</Link>
       {/* <label> Teams & condition |</label> 
       <Link to="/PrivacyPolicy">Privacy Policy</Link> */}
       </RightContainer>
    </FooterHeading>
 </Container>
  </>
}
  </>:<AppContainer>
      <NavBar/>
    <BodyContainer className="BodyContainer">
      <Sidebar />
      <Content />
    </BodyContainer>
    <FooterHeading>
           <LeftContainer>
             D4u Private Limited © Copyrights 2022. All Rights Reserved. | Designed & Developed by <a href="https://illusionspotline.com/">ISL</a>
           </LeftContainer>
           <RightContainer>
             <Link to="/PrivacyPolicy">Privacy Policy</Link>
            </RightContainer>
         </FooterHeading>
    </AppContainer>
   }
  </>

  );
}
export default ProviderPage;