import React from "react";
import tw from "twin.macro";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import ProviderPage from "../Pages/ProviderPage";
// import { Provider } from "react-redux";
// import {store} from '../src/Components/Redux/Store.js'

const RoutingComponent=()=>
{
    //const sign_in_state=useSelector((state)=>state.app.value);
    return(
        <Router>
          <Routes>
            <Route exact path="/" element={<ProviderPage />} />
            <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy/>}/>
          </Routes>
      </Router>
    )
};
export default RoutingComponent;