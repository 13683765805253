import { configureStore } from '@reduxjs/toolkit';
import AppReducer from './AppSlice';
import ComponentReducer from './ComponentSlice';
import MetaReducer from './MetaSlice';
export const store = configureStore({
  reducer: {
    app:AppReducer,
    callComponent:ComponentReducer,
    meta:MetaReducer
  },
})