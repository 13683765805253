import React, { useState,useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import bannerimage from "../../Images/bannerimage.png";
import {storage} from '../../FirebaseInit.js';
import {ref, uploadBytesResumable, getDownloadURL,deleteObject} from "firebase/storage";
import { doc, setDoc,getDoc,updateDoc,arrayUnion,arrayRemove,collection,addDoc,getDocs, increment, FieldValue, serverTimestamp } from "firebase/firestore"; 
import {db} from '../../FirebaseInit.js';
import Imagepreview from "../ImagePreview";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { black } from "tailwindcss/colors";
import { async } from "@firebase/util";
import { messaging } from "../../FirebaseInit.js";
import {app} from '../../FirebaseInit.js'
import {getMessaging} from 'firebase/messaging';
import axios from "axios";
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` rounded bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.a`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.a`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const BlueButton = tw.a`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center cursor-pointer bg-primaryBlue hocus:bg-SecondaryBackground  text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const FormContainer = styled.div`
  ${tw`  bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-primaryBlue text-base font-medium tracking-wide border-b-2 py-2 text-primaryBlue hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-base`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;

const SelectContainer = tw.select`text-black bg-gray-200 py-2 rounded`
const OptionsContainer = tw.option`my-2`

const Link1 = tw.a`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-primaryBlue hocus:bg-SecondaryBackground text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;
export default ({ cards = null }) => {
  const[show_upload_modal,set_show_upload_modal]=useState(true);
  const[youtube_video_title,set_youtube_video_title]=useState("");
  const[youtube_video_id,set_youtube_video_id]=useState("");
  const[youtube_video_title_display,set_youtube_video_title_display]=useState("");
  const[youtube_video_id_display,set_youtube_video_id_display]=useState("");
  useEffect(()=>
  {
    FetchTheInformation();
  });
  async function FetchTheInformation()
  {
    const docRef = doc(db, "Youtube", "Youtube");
    const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
  set_youtube_video_id_display(docSnap.data().video_id);
  set_youtube_video_title_display(docSnap.data().video_title);
} else {
  // doc.data() will be undefined in this case
  console.log("No such document!");
}
  }
  const onChangeYoutubeVideoTitle=(e)=>
  {
    set_youtube_video_title(e.target.value);
    
  };
  const onChangeYoutubeVideoID=(e)=>
  {
    set_youtube_video_id(e.target.value);
  };
  const onUpdateYoutubeVideoID=()=>
  {
    if(youtube_video_id.length>0 && youtube_video_title.length>0)
    {
      UpdateTheYoutubeID();
    }
  }
  async function UpdateTheYoutubeID()
  {
    await updateDoc(doc(db, "Youtube","Youtube"), {
      video_title:youtube_video_title,
      video_id:youtube_video_id
    });
    set_youtube_video_id_display(youtube_video_id);
    set_youtube_video_title_display(youtube_video_title);
  }
  return (
    <Container>
      {show_upload_modal === true ? (
        <>
        <FormContainer>
          <form action="#">
            <TwoColumn>
              <ColumnForm>
                <InputContainer>
                  <Label htmlFor="name-input">Youtube Video Title</Label>
                  <Input
                    id="youtube-video-title"
                    type="text"
                    name="youtubevideotitle"
                    placeholder="Youtube Video Title"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "Youtube Video Title")}
                    value={youtube_video_title}
                    onChange={(e)=>onChangeYoutubeVideoTitle(e)}
                  />
                </InputContainer>
                <InputContainer>
                  <Label htmlFor="name-input">Youtube Video ID</Label>
                  <Input
                    id="youtube-video-id"
                    type="text"
                    name="youtubevideoid"
                    placeholder="Youtube Video ID"
                    //onFocus={(e) => (e.target.placeholder = "")}
                    //onBlur={(e) => (e.target.placeholder = "Youtube Video ID")}
                    value={youtube_video_id}
                    onChange={(e)=>onChangeYoutubeVideoID(e)}
                  />
                </InputContainer>
              </ColumnForm>
            </TwoColumn>
            <Actions>
              <Link1 
              onClick={()=>onUpdateYoutubeVideoID()}
              //onClick={()=>UploadAnImage()}
              >Update</Link1>
            </Actions>
          </form>
        </FormContainer>
        <TableContainer>
        <TableTop>
          <TableTitle style={{ width: "28%" }}>Video Title</TableTitle>

          <TableTitle style={{ width: "28%" }}>Video ID</TableTitle>
        </TableTop>
        <Column>
            <Card>
            <TableDetails style={{ width: "28%" }}>
                {youtube_video_title_display}
              </TableDetails>

              <TableDetails style={{ width: "28%" }}>
                {youtube_video_id_display}
              </TableDetails>
            </Card>
          </Column>
        </TableContainer>
        </>
      ) : null}

      {/*  */}

        {/* {
        banners_array.map((card, i) => (
          <Column key={i}>
            <Card>
              <TableDetails style={{ width: "13%" }}>
                <img src={card.banner_url} />
              </TableDetails>

              <TableDetails style={{ width: "28%" }}>
                {card.banner_name}
              </TableDetails>

              <TableDetails style={{ width: "28%" }}>
                {card.banner_date}
              </TableDetails>
              <TableDetails style={{ width: "28%" }}>
                {card.banner_purpose}
              </TableDetails>
              <TableDetails style={{ width: "18%" }}>
                <ActionIconsColumn>
                

                  <IconContainer onClick={()=>delete_the_banner(card.banner_purpose,card.banner_name,card.banner_url,card.banner_date)}>
                    <AiOutlineDelete />
                  </IconContainer>
                </ActionIconsColumn>
              </TableDetails>
            </Card>
          </Column>
        ))} */}
          {/* <IconContainer>
                    <AiOutlineEdit />
                  </IconContainer> */}
        {/* <ButtonsContainer>
          <Button href="/Login">
            <AiOutlineArrowLeft />
            <ButtonText>Previous</ButtonText>
          </Button>

          <BlueButton onClick={() => set_show_upload_modal(true)}>
            Create New Notification
          </BlueButton>
          <Button href="/Login">
            <ButtonText>Next</ButtonText>
            <AiOutlineArrowRight />
          </Button>
        </ButtonsContainer>
      </TableContainer> */}
    </Container>
  );
};
