import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { AiOutlineHome, AiOutlineTrademarkCircle, AiOutlineVideoCameraAdd, AiOutlineYoutube } from "react-icons/ai";
import { AiOutlineTable } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { componentReducer } from "./Redux/ComponentSlice";
const ButtonText = tw.h1`pl-2 `;
const ButtonText1 = tw.h1`pl-2 `;

const IconContainer = tw.div`text-xl`;
const SocialButtonsContainer = tw.div` w-2/12 pt-10 overflow-auto z-10 flex flex-col  items-center `;

const Link1 = tw.a`w-64 cursor-pointer font-semibold rounded-lg py-4 pl-4 text-xl text-black bg-gray-100 hocus:shadow-md hocus:bg-primaryOrange hocus:border-gray-600 flex items-center transition-all duration-300 focus:outline-none focus:shadow-outline ml-6 mr-8 mt-2 first:mt-0`

export default () => {
  const dispatch=useDispatch();
  const number = useSelector((state) => state.callComponent.value);
  return (
    <SocialButtonsContainer className="Sidebar">
      <Link1
        style={{ backgroundColor: number === 1 ? "#F89F03" : "#FFFFFF" }}
        onClick={() => dispatch(componentReducer(1))}
      >
        <IconContainer>
          <AiOutlineHome />
        </IconContainer>
        <ButtonText1>Dashboard</ButtonText1>
      </Link1>

      <Link1
        style={{ backgroundColor: number === 2 ? "#F89F03" : "#FFFFFF" }}
        onClick={() => dispatch(componentReducer(2))}
      >
        <IconContainer value={{ size: "24" }}>
          <CgProfile />
        </IconContainer>
        <ButtonText>Banners</ButtonText>
      </Link1>
      <Link1
        style={{ backgroundColor: number === 3 ? "#F89F03" : "#FFFFFF" }}
        onClick={() => dispatch(componentReducer(3))}
      >
        <IconContainer value={{ size: "24" }}>
          <CgProfile />
        </IconContainer>
        <ButtonText>Notifications</ButtonText>
      </Link1>
      <Link1
        style={{ backgroundColor: number === 4 ? "#F89F03" : "#FFFFFF" }}
        onClick={() => dispatch(componentReducer(4))}
      >
        <IconContainer>
          <AiOutlineTrademarkCircle />
        </IconContainer>
        <ButtonText1>Privacy Policy</ButtonText1>
      </Link1>
      <Link1
        style={{ backgroundColor: number === 5 ? "#F89F03" : "#FFFFFF" }}
        onClick={() => dispatch(componentReducer(5))}
      >
        <IconContainer>
          <AiOutlineVideoCameraAdd />
        </IconContainer>
        <ButtonText1>Videos</ButtonText1>
      </Link1>
      <Link1
        style={{ backgroundColor: number === 6 ? "#F89F03" : "#FFFFFF" }}
        onClick={() => dispatch(componentReducer(6))}
      >
        <IconContainer>
          <AiOutlineYoutube />
        </IconContainer>
        <ButtonText1>Youtube</ButtonText1>
      </Link1>
    </SocialButtonsContainer>
  );
};
