import React from "react";
import tw from "twin.macro";

const Container = tw.div `text-white`;
const PrivacySection = tw.div`md:mx-64 mx-4 md:my-20`;

const Heading = tw.div`text-5xl text-center mx-64 my-24 font-black text-white`;
const SubHeading = tw.div`text-lg my-4 text-textprimary font-semibold`;
const Paragraph = tw.div`opacity-75 text-lg`;

export default () => {
  return (
    <Container>
      <Heading>Privacy Policy</Heading>
       <PrivacySection>
        <Paragraph>
          Last modified November 23, 2022 with an effective date of November 23, 2022
This privacy policy ("Policy") informs you of practices when handling  your Information through the Services (both as defined below). In this Policy, "Day4u", "we" or "us" refers to Planext Private Limited., 

        </Paragraph>
        </PrivacySection>
      <PrivacySection>
        <SubHeading>Information we collect</SubHeading>
        <Paragraph>
         Information you provide to us
        </Paragraph>
        <Paragraph>
         Registration information : for example, when you create an account on the App, you will be asked to provide your name, email, phone number,. For Day4u, Day4u Business, or Twist Unlimited customers, which are Free Services, we also collect your mobile phone number for login usage only
        </Paragraph>
        <Paragraph>
         Information collected via voluntary activities: for example, when you communicate with us via email, or participate in surveys, we ask you to provide your email address and any Information you choose to provide to allow us to assist you. The Information we collect from you varies depending on the survey, but we typically ask you questions about your interests and habits, your opinion about different products and services and what you‘d like to be offered in the future. We also collect your ratings, opinions, preferences, questions, pictures, and responses associated with that survey.
        </Paragraph>
        <Paragraph>
          Information we automatically collect or is generated about you when use the Services
        </Paragraph>
        <Paragraph>
          Information you generate when using the Services: You may provide Information as part of your use of the Services, including any information you provide when sending messages through the Services. Also, if you choose to share and collaborate on a task with your co-workers or friends, we will collect the email address of your co-workers or friends.
        </Paragraph>
        <Paragraph>
          Information regarding your use of the Services , such as app use information, interactions with our team, and transaction records.
        </Paragraph>
        <Paragraph>
          Information not shared from third parties.
Children
Our Services are not targeted at children, and we do not knowingly collect Information from children under the age of 13. If you learn that a child has provided us with Information in violation of this Policy, please contact us as indicated below.

        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>How we use your personal information</SubHeading>
        <Paragraph>
          We use your Information to: Provide you with the Services. We will use your Information to perform our contractual obligation towards you to allow you to create an account and use the Services. The Information we process when doing so includes your registration information, information you provide to us when using the Services,  information you generate when using the Services, and information regarding your use of the Services such as transaction information. 
        </Paragraph>
        <Paragraph>
          Improve and monitor the Services. It is in our legitimate interests to improve our Services for our customers. When doing so, we may collect information we automatically collect or is generated about you when you use the Services, as well as non-personal information about your device such as device manufacturer, model and operating system, and the amount of free space on your device.
        </Paragraph>
        <Paragraph>
          Conduct analytics. It is in our legitimate interests to analyse the use of, and any other interaction or interest in our Services. When doing so we will process information we automatically collect or is generated about you when you use the Services to create anonymised and aggregated data regarding your App usage.
        </Paragraph>
        <Paragraph>
          Conduct surveys. From time to time, we may ask you to participate in surveys we conduct which are in our legitimate interest because they help us understand our userbase and improve the Services. If you participate, we process your registration information and any other information collected through the survey questions.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>How we use cookies and similar technologies</SubHeading>
        <Paragraph>
          Cookies are small files of letters and numbers that we store on your browser or the hard drive of your computer. They contain information that is transferred to your computer‘s hard drive.
        </Paragraph>
        <Paragraph>
         Our Services uses Cookies to collect information about your browsing activities and to distinguish you from other users of our Services. This aids your experience when you use our Services and also allows us to improve the functionality of our Services.
        </Paragraph>
        <Paragraph>
          We use the following cookies:
          Strictly necessary cookies: Some cookies are strictly necessary to make our Services available to you; for example, to perform your login functionality and for user authentication and security. We cannot provide you with the Services without this type of cookies.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Who we share your personal information with</SubHeading>
        <Paragraph>
        Other users who will see your profile information and any other information you choose to share with them through the Services.
        </Paragraph>
                <Paragraph>
      	Cloud service providers who we rely on for data storage, including  Firebase and Google cloud Services who are based in the U.S.;
        </Paragraph>
                <Paragraph>
         Analytics providers. We work with a number of analytics, segmentation and mobile measurement service providers who help us understand our userbase. This includes Google LLC, which is based in the U.S. You can learn about Google‘s practices by going to https://www.google.com/policies/privacy/partners/, and opt-out of them by downloading the Google Analytics opt-out browser add-on, available at 
        </Paragraph>
                <Paragraph>
         Communications platform providers , who help us manage and send newsletters to you in relation to the Services..
        </Paragraph>
                <Paragraph>
        Law enforcement agencies, public authorities or other judicial bodies and organisations. We disclose Information if we are legally required to do so, or if we have a good faith belief that such use is reasonably necessary to comply with a legal obligation, process or request; enforce our terms of service and other agreements, policies, and standards, including investigation of any potential violation thereof; detect, prevent or otherwise address security, fraud or technical issues; or protect the rights, property or safety of us, our users, a third party or the public as required or permitted by law (including exchanging information with other companies and organizations for the purposes of fraud protection).
        </Paragraph>
                <Paragraph>
         Although we have in place security measures to maintain the privacy and integrity of your Information, the transmission of Information via the internet is not completely secure. We may also take extra steps to protect your Information and minimise the Information we process. For example, when we store your Information, we use AES 256 encryption, and when we send or receive your Information, it is encrypted with TLS 1.1 or above. Additionally, we are not responsible for how third party integration services may collect, use or share the Information you send from the Apps. Please review the privacy policy of such third party integration partners before connecting those services to the Apps.
        </Paragraph>

      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Where we store your information</SubHeading>
        <Paragraph>
          Your Information will be processed by our employees and service providers in the Cloud service .
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>How long we store your information</SubHeading>
        <Paragraph>
         Your Information is kept for as long as necessary to achieve the purposes set out above. Generally, it is stored for as long as you are registered and using our Services, and then for up to 3 years from the date you stop using the Services, or promptly following a valid erasure request .Some information we collect will be stored for longer where we have an overriding legitimate interest to retain such information (for example, information on suspicious behavior of certain users of our Services and transaction records).
When deleting Information, we will take measures to make the Information irrecoverable or irreproducible, and electronic files which contain Information will be deleted permanently.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Your rights</SubHeading>
        <Paragraph>
          If you are based in the EEA, you have certain rights in relation to your Information. You will find more information below on when which rights can apply. To exercise your rights, please contact us at https://day4u.com/contact.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Contact & complaints</SubHeading>
        <Paragraph>
          We welcome questions, comments and requests regarding this Policy. For additional details and frequently asked questions about our policies, please read our 
If you wish to make a complaint about how we process your Information, please contact us at https://day4u.com/contact and we will endeavor to deal with your complaint as soon as possible. This is without prejudice to your right to launch a claim with a data protection authority. You can also send an email to us at support@day4u.com. Alternatively, if you are based in India, 

        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Changes</SubHeading>
        <Paragraph>
If we make any material changes to this Policy, we will post the updated Policy here and notify our users through the Services and/or newsletters. Please check this page frequently to see any updates or changes to this Policy.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

   
      {/* --------------------------------------------- */}
    </Container>
  );
};
