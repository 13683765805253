import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from 'firebase/auth';
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import {getMessaging} from 'firebase/messaging';
const firebaseConfig = {

  apiKey: "AIzaSyBX8uPYiyZnzpqVx5gzGmaxP23Wemx84tc",

  authDomain: "day4-329ca.firebaseapp.com",

  projectId: "day4-329ca",

  storageBucket: "day4-329ca.appspot.com",

  messagingSenderId: "133335155302",

  appId: "1:133335155302:web:afc5b073181f3df78323a7",

  measurementId: "G-QLGM47DJM9"

};
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth=getAuth(app);
export const storage = getStorage(app);
export const db=getFirestore(app);
export const messaging =getMessaging(app);