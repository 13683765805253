import tw from "twin.macro";
import { store } from "./Components/Redux/Store";
import { Provider } from "react-redux";
import RoutingComponent from "./Components/RoutingComponent";
export default function App() {
  return (
    <Provider store={store}>
      <RoutingComponent/>
    </Provider>
  );
}
